<template>
  <div class="height-100">
    <loading v-if="loading"></loading>
    <div v-else>
      <div v-if="movies.length > 0" style="overflow: scroll;height: 100vh;">
        <movit-item :list="movies"  :shou-top=false>
        </movit-item>
      </div>
      <div class="empt" v-else></div>
    </div>
  </div>
</template>

<script>
import MovitItem from "../../components/movie/MovieItem.vue";
export default {
  name: 'MoviesHome',
  data() {
    return {
      scrollTop: null,
 
      loading: false,
       

      movies: [],
      form: {
        cityCode: ''
      },
      cardId: '',//福利卡id
      filmName: '',
    }
  },
  created() { },
  components: { MovitItem },
  methods: {
    queryHotMoviesByCity() {
      this.$api
        .queryHotMoviesByCity(this.cardId, this.form.cityCode, this.filmName)
        .then((res) => {

          this.loading=false
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          this.movies = res.data
        })
        .catch((err) => {
          this.loading=false
          this.$Toast(err.msg);
        });
    },
  },
  // //在页面离开时记录滚动位置
  mounted() {
    this.cardId = localStorage.getItem("cardId");
    this.filmName = this.$route.query.filmName
    this.form.cityCode = localStorage.getItem("cityCode");
    this.queryHotMoviesByCity()
  },
}

</script>
<style  lang="scss" scoped>
//自定义指示器
.indicators {
  position: absolute;
  bottom: 5px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);

  .indicator {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.6;
    transition: opacity 0.5s,
      background-color 0.5s;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &:last-child {
      margin-right: 4px;
    }

    &.active {
      width: 1.2rem;
      height: 0.5rem;
      border-radius: 0.25rem;
      opacity: 1;
    }
  }
}

.backg {
  background-image: url("../../assets/images/movieback.png");
  background-size: 100% 100%;
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 10px;

  background-color: black;
}

.empt {
  height: 30rem;
  text-align: center;
  line-height: 15rem;
  font-size: 1.2rem;
  color: #DDD;
}
</style>